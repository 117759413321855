@import 'variables.scss';
@import 'general.scss';
@import 'typography.scss';
@import 'navbar.scss';
@import 'form.scss';
@import 'sidebar.scss';
@import 'buttons.scss';
@import 'cards.scss';
@import 'dropFileZone';
@import 'miniPie.css';
@import 'monthTable';
@import 'datagrid';
@import 'bootstrap-daterangepicker/daterangepicker.css';

.row {
  --bs-gutter-x: 2rem;
}

body,
html,
#root {
  height: 100%;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 2.5rem);
  padding-left: var(--bs-gutter-x, 2.5rem);
  margin-right: auto;
  margin-left: auto;
}

/* FINE FORM */

/* INIZIO TABS */

.tabsContainer {
  background: #ffffff 0 0 no-repeat padding-box;
  border: 1px solid var(--input-border-color);
  border-radius: 12px;
  opacity: 1;
  flex: 1 1 100%;
  margin: 24px;
  overflow: hidden;
}

.nav-tabs {
  border-bottom: 1px solid var(--brand-light);
  height: 64px;
  display: flex;
  flex: 1 1 100%;
  align-items: center;

  a:first-child {
    margin-left: 10px;
  }

  .nav-link {
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0;
    text-transform: uppercase;
    color: var(--brand);
    height: 64px;
    display: flex;
    align-items: center;
    border-style: none !important;

    &.active {
      border-bottom: 4px solid var(--brand) !important;
      color: var(--brand) !important;
      font-size: 18px;
      font-weight: bold;
      line-height: 21px;
    }
  }
}

/* FINE TABS */

/* INIZIO GENERICI */

*:focus {
  outline: none;
  box-shadow: none !important;
}

.hidden {
  visibility: hidden;
}

hr.divider {
  background-color: var(--input-border-color);
  opacity: 1;
}

.no-padding {
  padding: 0;
}

/*INIZIO SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  // border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--brand) 0 0% no-repeat padding-box;
  border-radius: 4px;
}

/*FINE SCROLLBAR */

select:not(.preserve-case) option {
  text-transform: uppercase;
}

/* FINE GENERICI */

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(244, 244, 244, 0.7);
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.visible {
    display: flex !important;
  }
}

.tabs-chart {
  height: max-content !important;

  & .nav-item > * {
    height: max-content !important;
    justify-content: space-around;
  }

  .tab-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .tab-summary {
      text-transform: none !important;

      &.negative {
        color: tomato !important;
      }
    }
  }
}

.tableAction {
  color: var(--brand);

  .icon {
    padding: 4px;
    cursor: pointer;
  }

  button.btn-action:hover {
    color: var(--brand-dark);
  }
}

.text-highlight {
  background: var(--brand-extra-light);
}

@mixin percentages {
  @for $i from 0 through 100 {
    .nav-tabs .nav-link.percent-#{$i} {
      background: linear-gradient(
            to right,
            #0cd054 0%,
            #0ff262 50%,
            #ffffff 100%
          )
          no-repeat
          bottom
          left
          10px,
        linear-gradient(to right, #bf0a0a 0%, #f20c0c 50%, #ffffff 100%)
          repeat-y
          top
          left
          10px;
      background-size: 15px ($i + 1) * 1%;
    }
  }
}

@include percentages;
