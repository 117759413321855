$disabled-opacity: 0.25;

.form-group {
  padding-bottom: 10px;
  position: relative;

  .error-message {
    color: red;
    font-size: 12px;
  }

  &.required {
    .form-label:before {
      content: '*';
      display: inline-block;
      color: red;
      margin-right: 6px;
    }
  }

  .date-picker.MuiTextField-root {
    display: block;
  }

  .date-picker .MuiOutlinedInput-root {
    width: 100%;
    
    input {
      border: 0;

      &:focus {
        border: 0;
      }
    }
  }

  label {
    text-align: left;
    font-size: 13px;
    line-height: 18px;
    color: var(--text-color);
    opacity: 1;
  }

  select,
  input:not([type='checkbox']),
  .form-control {
    height: 40px;
    border: 1px solid var(--input-border-color);
    border-radius: 6px;
    opacity: 1;
    letter-spacing: 0;
    min-width: 248px;
    color: var(--input-text-color);
    padding: 0 16px;
    font-weight: bold;
    outline: none;
    font-size: 15px;

    &:focus {
      border: 1px solid var(--input-border-focus-color);
      color: var(--input-text-color);
    }

    &:disabled {
      background-color: var(--input-border-color);
      opacity: 0.54;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--input-border-color);
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--input-border-color);
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      color: var(--input-border-color);
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: var(--input-border-color);
    }
  }

  textarea.form-control {
    height: auto;
    min-height: 40px;
    padding: .375rem .75rem;
  }

  .form-check {
    &-input {
      border-color: var(--brand-dark);
      cursor: pointer;

      &:checked {
        background-color: var(--brand);
      }

      &:disabled {
        opacity: $disabled-opacity !important;
        filter: grayscale(1);

        & ~ .form-check-label {
          opacity: $disabled-opacity !important;
          filter: grayscale(1);
        }
      }
    }

    label {
      font-size: 13px;
      color: var(--text-color);
      letter-spacing: 0;
      margin-left: 0;
      cursor: pointer;
    }
  }

  select {
    background-image: url('./icons-png/chevron-down.svg');
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) center;
    cursor: pointer;

    &:focus {
      background-image: url('./icons-png/chevron-up.svg');
    }
  }

  input[name='dateRange'] {
    background-image: url('./icons-png/icon-calendar.png');
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: 20px center;
    padding-left: 64px;
  }

  .dropdown {
    position: relative;
    align-items: center;
  }
}

.multi-select-user {
  .dropdown-menu {
    max-height: 65vh;
    overflow: auto;
  }

  &.multi-select-small {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .dropdown-menu {
      max-height: 25vh;
    }
  }

  .inactive-user {
    color: var(--text-color);

    &:active,
    &.active {
      color: var(--input-border-color);
      background: slategray;
    }
  }
}

.pills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .pill {
    display: inline-block;
    border: 1px solid #ddd;
    border-radius: 16px;
    padding: 2px 9px;
    margin: 4px 4px 4px 0;
    background: #eee;

    &.removable-pill:after {
      content: '×';
      color: #ccc;
    }

    &:hover,
    &:focus {
      background: #fdd;
      border-color: #ecc;
      cursor: pointer;

      &.removable-pill:after {
        color: red;
      }
    }
  }
}

.inline-fields {
  display: flex;
  max-width: 100%;
  width: 100%;
  gap: 12px;
  @media (max-width: 920px) {
    & {
      flex-direction: column;

      & > * {
        flex: 1 !important;
      }
    }
  }

  & > * {
    flex: 1;

    &.form-group input {
      min-width: unset;
    }
  }
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
  color: var(--text-color);
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--gray-90);
}

.MuiButtonBase-root.MuiMenuItem-root.Mui-selected:hover,
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected:hover,
.MuiPickersYear-root .MuiPickersYear-yearButton.Mui-selected:hover,
.MuiPickersMonth-root .MuiPickersMonth-monthButton.Mui-selected:hover,
.MuiButtonBase-root.MuiPickersDay-root:hover {
  background-color: var(--brand-dark);
}

.MuiButtonBase-root.MuiMenuItem-root.Mui-selected,
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected,
.MuiPickersYear-root .MuiPickersYear-yearButton.Mui-selected,
.MuiPickersMonth-root .MuiPickersMonth-monthButton.Mui-selected {
  background-color: var(--brand);
}

.MuiButtonBase-root.MuiPickersDay-root:focus,
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected:focus,
.MuiPickersYear-root .MuiPickersYear-yearButton.Mui-selected:focus,
.MuiPickersMonth-root .MuiPickersMonth-monthButton.Mui-selected:focus,
.MuiButtonBase-root.MuiMenuItem-root.Mui-selected:focus {
  background-color: var(--brand-dark);
}

.month-container .MuiInputBase-input {
  text-transform: capitalize;  
  font-size: 22px;
  color: var(--brand);
  width: 175px;
}

.month-container fieldset {
  border: 0;
}