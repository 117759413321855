@import 'variables.scss';

.headerPage {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background-color: #fff;
  padding: 0 24px;
  flex: 0 0 72px;
  width: 100%;
  border-bottom: 1px solid var(--input-border-color);

  .title {
    flex-grow: 0;
  }

  .monthNavContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 1 auto;
    margin-left: 31px;
  }

  .buttonGroup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 1 auto;
    margin-left: 31px;
    min-width: 98px;
  }
  .dateRange {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    justify-content: flex-end;
  }

  .arrow {
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    color: var(--brand-light);
  }

  .divider {
    display: inline-block;
    width: 2px;
    background-color: var(--brand-extra-light);
    margin: 0 16px;
    height: 25px;
  }

  .yearContainer {
    min-width: 120px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0;
    color: var(--brand);
    text-transform: capitalize;
  }
  select.yearContainer {
    padding-right: 36px;
    appearance: none;
  }
}
