.navbar {
  &__logo {
    margin: 16px 8px 36px;
  }

  &__wrapper {
    background-color: var(--brand);
    width: 56px;
    flex: 0 0 56px;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    color: var(--white);

    .nav {
      height: 100%;
      align-items: center;
      flex-direction: column;
      flex-wrap: nowrap;
      overflow-y: auto !important;
      overflow-x: hidden !important;
    }

    .btn-icon {
      position: relative;
      padding: 0;
      font-size: 18px;
      height: 32px;

      &:before {
        content: '';
        border-radius: 0 4px 4px 0;
        width: 4px;
        background-color: var(--white);
        position: absolute;
        left: -6px;
        top: -2px;
        height: 100%;
        max-height: 36px;
        transition: left ease-in 0.2s;
      }

      &.active,
      &:hover {
        &:before {
          left: -1px;
        }
      }
    }
  }
}
