.card-header-with-line {
  border-Bottom: 1px solid var(--gray-90);
  margin: 0 -48px;
  padding: 0 48px 48px 48px;
}

.card-footer-without-line {
  text-align: right;
  margin: 0px -64px 0 -64px;
  padding: 32px 64px 0 64px;
}

.card-footer-with-line {
  text-align: right;
  margin: 32px -64px 0 -64px;
  padding: 32px 64px 0 64px;
  border-top: 1px solid var(--gray-90);
}

.retrospective-cards {
  display: grid;
  gap: 10px 10px;
  margin: 20px;

  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: 1540px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 850px) {
    grid-template-columns: auto;
  }
}

.retrospective-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 14px;
  border: 1.5px solid var(--gray-90) !important;
  padding: 14px;
}

.retrospective-card-title {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: var(--gray-45);

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 30px;
    height: 30px;
  }
}

.retrospective-card-content {
  display: flex;
}

.retrospective-card-data {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-left: 1px solid var(--gray-90);
  padding-left: 14px;
  padding-right: 14px;

  &:first-child {
    border: none;
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.retrospective-card-data-header {
  font-size: 14px;
  font-weight: 600;
}

.retrospective-card-data-body {
  font-size: 56px;
  font-weight: 400;
}

.retrospective-card-data-footer {
  font-size: 12px;
  font-weight: 400;
}