button.btn,
a.btn {
  border-radius: 8px;
  opacity: 1;
  padding: 0 12px;
  height: 48px;
  font-size: 14px;
  font-weight: bold;
  transition: all ease-in-out 0.2s;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.24;
  }

  svg:not(:only-child) {
    margin-right: 8px;
  }

  &:not(.btn-icon) + .btn {
    margin-left: 8px;
  }

  &-icon {
    color: var(--white);
    margin: 8px 0;
    line-height: unset;

    &.active,
    &:hover {
      color: var(--white);
    }
  }

  &-action {
    color: var(--brand);

    svg {
      margin: 0;
    }
  }

  &-primary {
    background: var(--brand);
    border-color: var(--brand);

    svg,
    [class^='icon-'],
    [class*=' icon-'] {
      margin-right: 8px;
      font-size: 18px;
    }

    &:hover,
    &:active {
      background: var(--brand-dark);
      border-color: var(--brand-dark);
    }

    &:focus {
      box-shadow: 0 0 16px #1579ff29;
      background: var(--brand-dark);
      border-color: var(--brand-dark);
    }
  }

  &-outline-primary {
    border-color: var(--brand);
    color: var(--brand);

    &:hover,
    &:active {
      background-color: var(--white);
      border-color: var(--brand);
      color: var(--brand);
    }
  }

  &-secondary {
    color: var(--brand);
    background-color: #ffffff;
    border: 0;

    &:hover,
    &:active,
    &:focus {
      color: var(--brand);
      background-color: #ffffff;
    }
  }

  &--light {
    [class^='icon-'],
    [class*=' icon-'] {
      font-size: 24px;
      color: var(--brand);
      margin-right: 16px;
      margin-left: -8px;
    }
  }
}

.show > .btn-primary.dropdown-toggle {
  background-color: var(--brand-dark);
  border-color: var(--brand-dark);
}

.dropdown-item.active, .dropdown-item:active {
  background-color: var(--brand-dark);
}

.btn-primary:disabled {
  background-color: var(--brand-dark);
  border-color: var(--brand-dark);
}

.back-button {
  color: var(--brand);
  cursor: pointer;
  margin-right: 10px;
}