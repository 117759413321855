/** Source: https://www.freecodecamp.org/news/css-only-pie-chart/ */
.pie {
  --p: 20;
  --b: 15px;
  --c: red;
  --w: 30px;

  width: var(--w) !important;
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
}

.pie.inflexible {
  flex-shrink: 0;
  align-self: flex-end;
}

.pie:before,
.pie:after {
  content: '';
  position: absolute;
  border-radius: 50%;
}

.pie:before {
  inset: 0;
  border: 1px solid var(--c);
}

.pie:after {
  inset: 0;
  background: conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
}

.animate {
  animation: p 0.3s 0.1s both;
}

@keyframes p {
  from {
    --p: 0;
  }
}
