.sidebar {
  max-width: 282px;
  width: 282px;
  border-left: 1px solid var(--input-border-color);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all ease-in 0.2s;

  &.closed {
    max-width: 62px;
  }

  &__header {
    height: 72px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-bottom: 1px solid var(--input-border-color);
    font-weight: bold;
    font-variant: normal;
    font-style: normal;
    color: var(--title-color);
    white-space: nowrap;

    [class^='icon-'],
    [class*=' icon-'] {
      height: 32px;
      width: 32px;
      background: #005eb814;
      border-radius: 8px;
      font-size: 24px;
      text-align: center;
      flex-shrink: 0;
      line-height: 32px;
      margin-right: 12px;
      color: #7b818f;
    }
  }

  &__body {
    padding: 0 16px;
    margin-bottom: 24px;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__footer {
    padding: 0 16px;
    height: 64px;
    flex-shrink: 0;
    border-top: 1px solid var(--input-border-color);
    display: flex;
    align-items: center;
  }
}
