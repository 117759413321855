.drop-file {
  .selected-file-wrapper {
    .card {
      &.hidden {
        display: none;
      }
    }
    ul {
      svg {
        cursor: pointer;
      }
    }
  }
}