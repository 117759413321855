@import 'variables';

.daterangepicker {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 20px;
  .drp-calendar {
    &.left {
      border-right: 1px solid #ddd;
      padding: 15px;
    }
    &.right {
      padding: 15px 15px 15px 23px;
    }
  }
  .month {
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.75 !important;
    letter-spacing: 0.00938em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
  }
  td {
    font-size: 16px !important;
    width: 36px !important;
    height: 36px !important;
  }
}

.popover {
  border: 1px solid var(--brand-light);
  .popover-body {
    padding: 12px 6px;
    .popoverBody {
      display: flex;
      width: 80px;
      justify-content: space-around;
    }
  }
}

.bs-popover-bottom {
  .popover-arrow {
    &::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: var(--brand-light);
    }
  }
}

.spinnerPageContent {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1031;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .spinnerContainer {
    margin: auto;
  }
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: var(--brand);
}
