@import 'variables.scss';

.I3Modal {
  .modal-content {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #7b818f1f;
    border-radius: 12px;
    opacity: 1;

    .modal-header {
      padding: 20px 24px;

      .modal-title {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        letter-spacing: 0;
        color: var(--title-color);
      }
    }

    .modal-body {
      padding: 20px 24px 0 24px;

      .buttonContent {
        height: 65px;
        border-top: 1px solid var(--gray-90);
        border-radius: 0 0 12px 12px;
        opacity: 1;
        display: flex;
        align-items: center;
        text-align: right;
        margin: 0px -24px;
        padding: 12px;
        background: #ffffff;
        justify-content: flex-end;

        .form-group {
          padding-bottom: 0;
        }
      }
    }
  }
}

.I3Modal-confirm {
  .modal-content {
    height: 320px;

    .question-title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      color: var(--text-color);
      opacity: 1;
      margin-top: 20px;
      margin-bottom: 16px !important;
    }

    .question-text {
      text-align: center;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0;
      color: var(--text-color);
      opacity: 0.6;
    }

    .question-help {
      text-align: center;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0;
      font-weight: bold;
      color: var(--text-color);
      opacity: 0.6;
    }

    .center-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .warning-icon-content {
        width: 64px;
        height: 64px;
        border: 1px solid var(--input-border-color);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .modal-body {
      padding: 15px 0 0 0;

      .buttonContent {
        margin: 40px 0 0 0;
      }
    }
  }
}

.I3Modal-add-km,
.I3Modal-confirm-km {
  .modal-content {
    width: 480px;
  }
}

.I3Modal-insert-training-expenses {
  $w: 700px;

  .modal-dialog {
    max-width: calc($w + 3.5rem);
  }

  .modal-content {
    width: $w;
    max-width: 90vw;
  }
}
