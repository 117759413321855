.month-table {

    th,
    td {
        font-weight: bold;
        border-color: var(--gray-90);
        padding: 0;
    }

    th {
        color: var(--brand-dark);
        text-transform: uppercase;
   
        &.today {        
            border-top: 1px solid var(--brand-dark);
        };
    }

    td {
        color: var(--text-color);
        
        &.error {
            color: var(--red-45);
        };
    
        &.greyedOut {
            background-color: var(--gray-95);
        };
    }

    th:nth-child(-n + 2),
    td:nth-child(-n + 2) {
        position: sticky;
        background-color: #fff;
    }

    th:nth-child(-n + 2) {
        z-index: 2;
    }

    th:nth-child(n + 3) {
        z-index: 1;
    }
    
    td:nth-child(-n + 2) {
        z-index: 1;
    }

    td:nth-child(n + 3) {
        line-height: 0;
    }
    
    th:first-of-type,
    td:first-of-type {
        padding-left: 8px;
        min-width: 150px;
        max-width: 150px;
        left: 0px;
    }

    th:nth-child(2),
    td:nth-child(2) {
        left: 150px;
    }

    th:not(:first-of-type),
    td:not(:first-of-type) {
        min-width: 42px;
        max-width: 42px;
        text-align: center;        
    }

    th:not(:first-of-type) {
        line-height: 12px;
        padding: 8px;
    }
    
    td:not(:first-of-type) {
        padding: 12px 0px;
    }

    th > div:nth-child(2),
    td:first-of-type > div:nth-child(2) {
        font-size: 12px;
        font-weight: normal;
    }
        
    td:first-of-type > div:nth-child(1) {
        font-weight: bold;
        text-transform: uppercase;
    }

    th:nth-child(2),
    td:nth-child(2) {
        border-right: 1px solid var(--gray-90);
    }

    td div {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    td input {
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        color: var(--text-color);
    }

    .holiday {
        background-color: var(--red-95);
        color: var(--red-45);
    };

    .weekend {
        background-color: var(--brand-extra-light);
    };

    .today {
        border-left: 1px solid var(--brand-dark);
        border-right: 1px solid var(--brand-dark);
    };
}
