.MuiDataGrid-toolbarContainer .MuiButtonBase-root,
.MuiDataGrid-columnHeaders,
.MuiDataGrid-footerContainer,
.MuiDataGrid-footerContainer .MuiTablePagination-root {
    color: var(--brand-dark);
}

.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle {
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: normal;
    line-height: normal;
}

.MuiDataGrid-virtualScroller {
    color: var(--text-color);
}

.MuiDataGrid-root .MuiDataGrid-virtualScroller .MuiDataGrid-row.even {
    background-color: var(--gray-95);
}

.MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-row .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-row .MuiDataGrid-cell:focus-within {
    outline: none;
}