:root {
  --brand-dark: #00468A;
  --brand: #005EB8;
  --brand-medium: #0075E5;
  --brand-light: #CCE6FF;
  --brand-extra-light: #E5F3FF;  
  --black: #202020;
  --gray-45: #696F7C;
  --gray-90: #E3E5E7;
  --gray-95: #FAFAFA;
  --white: #FFFFFF;
  --red-30: #941600;
  --red-40: #C20600;
  --red-45: #E50800;
  --red-90: #FFCECC;  
  --red-95: #FFF0F0;

  --text-color: var(--gray-45);
  --input-text-color: var(--black);
  --input-border-color: var(--gray-90);
  --input-border-focus-color: var(--gray-45);
  --title-color: var(--black);
}