@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap');
html,
body * {
  font-family: 'Open Sans', sans-serif;
}

.version__txt {
  bottom: 0;
  color: var(--white);
  opacity: 0.54;
  font-size: 12px;
  margin: 12px 0;
}

.title {
  font-weight: bold;
  font-variant: normal;
  font-style: normal;
  letter-spacing: 0;
  color: var(--title-color);
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  display: flex;
}

.subtitle{
  font-weight: normal;
  color: var(--text-color);
  font-size: 80%;
}

.total__details {
  display: flex;
  height: 60px;
  align-items: center;
  &__label {
    flex: 1;
    font-size: 14px;
    color: var(--text-color);
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  &__icon {
    font-size: 18px;
    margin-right: 8px;
  }

  &__value {
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    color: var(--input-text-color);
  }

  &__value.error {
    color: var(--red-45);
  }
}

.report-status {
  display: flex;
  padding: 8px 16px;
  font-size: 12px;
  border-radius: 24px;
  align-items: center;

  [class^='icon-'],
  [class*=' icon-'] {
    font-size: 18px;
    margin-right: 8px;
  }

  &.open {
    background-color: #ffd4d4;
    color: red;
  }
  
  &.closed {
    background-color: #4bb54345;
    color: #246d1f;
  }
}

.expenses-report-status {
  background: #4bb54345;
  padding: 12px;
  border-radius: 12px;
  color: #246d1f;

  [class^='icon-'],
  [class*=' icon-'] {
    margin-right: 12px;
  }

  .expenses-report-status-note {
    font-size: 14px;
    margin-top: 12px;
  }
}

@font-face {
  font-family: 'icomoon';
  src: url('./icon-font/icomoon.eot?4r70wq');
  src: url('./icon-font/icomoon.eot?4r70wq#iefix') format('embedded-opentype'),
    url('./icon-font/icomoon.ttf?4r70wq') format('truetype'),
    url('./icon-font/icomoon.woff?4r70wq') format('woff'),
    url('./icon-font/icomoon.svg?4r70wq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-double-left:before {
  content: '\e92f';
}
.icon-arrow-double-right:before {
  content: '\e930';
}
.icon-expenses:before {
  content: '\e931';
}
.icon-document:before {
  content: '\e900';
}
.icon-arrow-down:before {
  content: '\e901';
}
.icon-arrow-left:before {
  content: '\e902';
}
.icon-arrow-right:before {
  content: '\e903';
}
.icon-arrow-up:before {
  content: '\e904';
}
.icon-bag:before {
  content: '\e905';
}
.icon-calendar:before {
  content: '\e906';
}
.icon-camera:before {
  content: '\e907';
}
.icon-car:before {
  content: '\e908';
}
.icon-card:before {
  content: '\e909';
}
.icon-check:before {
  content: '\e90a';
}
.icon-check-circle:before {
  content: '\e90b';
}
.icon-close:before {
  content: '\e90c';
}
.icon-close-circle:before {
  content: '\e90d';
}
.icon-detail:before {
  content: '\e90e';
}
.icon-detail-off:before {
  content: '\e90f';
}
.icon-directory:before {
  content: '\e910';
}
.icon-download:before {
  content: '\e911';
}
.icon-edit:before {
  content: '\e912';
}
.icon-edit-square:before {
  content: '\e913';
}
.icon-filter:before {
  content: '\e914';
}
.icon-group:before {
  content: '\e915';
}
.icon-info-circle:before {
  content: '\e916';
}
.icon-lock:before {
  content: '\e917';
}
.icon-logout:before {
  content: '\e918';
}
.icon-love:before {
  content: '\e919';
}
.icon-mail:before {
  content: '\e91a';
}
.icon-minus-circle:before {
  content: '\e91b';
}
.icon-monitor:before {
  content: '\e91c';
}
.icon-notification:before {
  content: '\e91d';
}
.icon-notification-circle:before {
  content: '\e91e';
}
.icon-phone:before {
  content: '\e91f';
}
.icon-phone_1:before {
  content: '\e920';
}
.icon-plus-circle:before {
  content: '\e921';
}
.icon-position:before {
  content: '\e922';
}
.icon-save:before {
  content: '\e923';
}
.icon-settings:before {
  content: '\e924';
}
.icon-shield:before {
  content: '\e925';
}
.icon-sorte:before {
  content: '\e926';
}
.icon-star:before {
  content: '\e927';
}
.icon-thumb:before {
  content: '\e928';
}
.icon-time:before {
  content: '\e929';
}
.icon-trash:before {
  content: '\e92a';
}
.icon-upload:before {
  content: '\e92b';
}
.icon-user:before {
  content: '\e92c';
}
.icon-world:before {
  content: '\e92d';
}
.icon-zoom:before {
  content: '\e92e';
}
.icon-upload:before {
  content: '\e92b';
}
.icon-user:before {
  content: '\e92c';
}
.icon-world:before {
  content: '\e92d';
}
.icon-zoom:before {
  content: '\e92e';
}


.text-small {
  font-size: 80%;
}
.text-large {
  font-size: 110%;
}
.text-bold {
  font-weight: bold;
}
.text-link{
  cursor: pointer;
  font-weight: bold;
  color: var(--brand);
  text-decoration: none;
}