body {
  margin: 0;
  background: #ffffff 0 0 no-repeat padding-box;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 35px;
  height: 35px;
  padding: 5px 0 6px;
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 1.42857;
  margin-top: -3px;
  position: relative;
}
